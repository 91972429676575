import React, { useState } from "react";
import "../authPages.css";
import { Link } from "react-router-dom";
import LoginHook from "../../../CustomHooks/Auth/LoginHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import AuthTitle from "../../../components/Auth/AuthTitle/AuthTitle";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
const LoginPage = () => {
  const [showPassWordIcon, setShowPasswordIcon] = useState(faEyeSlash);
  const [passwordState, setPasswordState] = useState("password");
  const [loginValue,password,onChangeLoginValue,
    onChangePassword,onSubmit,loading,isPress,
  ] = LoginHook();
  const handlePasswordState = () => {
    passwordState === "password"
      ? setPasswordState("text")
      : setPasswordState("password");
    showPassWordIcon === faEyeSlash
      ? setShowPasswordIcon(faEye)
      : setShowPasswordIcon(faEyeSlash);
  };
  const lang = Cookies.get("i18next")
  const handleShowPassword =()=>{
    return lang==="en"?"show-password":"show-password-ar"
  } 
  const [t] = useTranslation()
  return (
    <div className="login-page auth-page page">
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
           <AuthTitle value={"SIG"}/>
          </div>
          <div className="login-card auth-card ">
            <div className="card-body">
              <h4 className="card-title mb-4">{t("authPages.loginPage.title")}</h4>
              <form>
                {/* <button className="btn google mb-4">
                  <i className="fa fa-google"></i> &nbsp; {t("authPages.loginPage.loginWithGoogle")}
                </button> */}
              </form>
              <form onSubmit={onSubmit}>
                <div className="form-group">
                <label> {t("authPages.loginPage.emailOrPhone")}</label>
                  <input
                    value={loginValue}
                    onChange={onChangeLoginValue}
                    className="custom-input"
                    placeholder={t("authPages.loginPage.emailOrPhone")}
                    type="text"
                    required
                  />
                </div>
                <div className="form-group form-password">
                  <label>{t("authPages.loginPage.password")}</label>
                  <span className={handleShowPassword()} onClick={handlePasswordState}>
                    <FontAwesomeIcon icon={showPassWordIcon} />
                  </span>
                  <input
                    value={password}
                    onChange={onChangePassword}
                    className="custom-input"
                    placeholder={t("authPages.loginPage.password")}
                    type={passwordState}
                    required
                  />
                </div>
                <div className="form-group">
                  {/* <Link to="/forget-pass-page" className="float-right">
                    Forgot password?
                  </Link> */}
                </div>
                <div className="form-group">
                  <BtnComponent
                  value={t("authPages.loginPage.title")}
                  type={"submit"}
                  loading={loading===true&&isPress===true?true:false}
                  />
                </div>
              </form>
            </div>
            <div className="sign-up-info p-4">
            <p className="text-start">
            {t("authPages.loginPage.dontHaveAccount")} <Link to="/sign-up-page"> {t("authPages.loginPage.signUp")}</Link>
            </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;
