import React from "react";
import ContactUsSidebar from "../../components/ContactUs/ContactUsSidebar/ContactUsSidebar";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import ContactUsHook from "../../CustomHooks/OurSettings/ContactUs/ContactUsHook";
import BtnComponent from "../../components/Utility/BtnComponent/BtnComponent";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import { useTranslation } from "react-i18next";

const ContactUsPage = () => {
  const  [email,name,phone,subject,message,website,onChangeName,onChangeEmail,onChangePhone
    ,onChangeSubject,onChangeMessage,onChangeWebsite,onSubmit,loading,isPress,
  ] = ContactUsHook()
  const [t] = useTranslation()
  return (
    <div className="contact-us-page page">
      <InternalHeader  contactUsActive={"contactUsActive"} />
      <BreadcrumbSection title={t("contactUsPage.title")}/>
      <section className="wrap__contact-form">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h5 className="fw-bold">{t("contactUsPage.title")}</h5>
             <form onSubmit={onSubmit} className="p-3">
             <div className="row">
                <div className="col-md-6">
                  <div className="form-group form-group-name">
                    <label className="text-capitalize">
                    {t("contactUsPage.name")} <span className="required"></span>
                    </label>
                    <input
                    value={name}
                    onChange={onChangeName}
                      type="text"
                      className="custom-input"
                      name="name"
                      placeholder={t("contactUsPage.name")}
                      required=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group form-group-name">
                    <label className="text-capitalize">
                    {t("contactUsPage.phone")} <span className="required"></span>
                    </label>
                    <input
                    value={phone}
                    onChange={onChangePhone}
                    placeholder={t("contactUsPage.phone")}
                      type="text"
                      className="custom-input"
                      name="phone"
                      minLength={11}
                      maxLength={11}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group form-group-name">
                    <label className="text-capitalize">
                    {t("contactUsPage.email")} <span className="required"></span>
                    </label>
                    <input
                      type="email"
                      value={email}
                    onChange={onChangeEmail}
                      className="custom-input"
                      name="email"
                      placeholder={t("contactUsPage.email")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group form-group-name">
                    <label>
                    {t("contactUsPage.website")} <span className="required"></span>
                    </label>
                    <input
                      type="text"
                      value={website}
                    onChange={onChangeWebsite}
                      className="custom-input"
                      name="website"
                      placeholder={t("contactUsPage.website")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group form-group-name">
                    <label>
                    {t("contactUsPage.subject")} <span className="required"></span>
                    </label>
                    <input
                    value={subject}
                    onChange={onChangeSubject}
                      type="text"
                      className="custom-input"
                      name="subject"
                      placeholder={t("contactUsPage.subject")}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="text-capitalize">{t("contactUsPage.message")} </label>
                    <textarea
                    value={message}
                    onChange={onChangeMessage}
                      className="custom-input"
                      rows="9"
                      name="message"
                      placeholder={t("contactUsPage.message")}
                      required
                    ></textarea>
                  </div>
                  <div className="form-group float-right mb-0">
                    <div className="btn-box btn-contact">
                    <BtnComponent
                    loading={isPress===true && loading===true ? true:false}
                     type={"submit"} value={t("contactUsPage.submit")}/>
                    </div>
                  </div>
                </div>
              </div>
             </form>
            </div>
            <div className="col-md-4">
              <ContactUsSidebar />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
