import React from "react";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import AboutUsHook from "../../CustomHooks/OurSettings/AboutUs/AboutUsHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
import HtmlToTextHook from "../../CustomHooks/HtmlToTextHook/HtmlToTextHook"
const AboutUsPage = () => {
  const [data, loading] = AboutUsHook();
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  const [htmlToText] = HtmlToTextHook()
  return (
    <div className="about-us-page page">
      <InternalHeader aboutUsActive={"aboutUsActive"} />
      <BreadcrumbSection title={t("aboutUsPage.title")} />
      <section className="home__about bg-light">
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-6">
              <div className="title__leading text-start">
                {loading === false ? (
                  data ? (
                    <>
                      <h2 className="text-capitalize">
                        {
                          lang==="en"?
                          data?.data_trans
                          ? data?.data_trans[1]?.title_en
                          : null
                          :
                          data?.data_trans
                          ? data?.data_trans[0]?.title_ar
                          : null

                        }
                        {}
                      </h2>
                      <p>
                        {lang==="en"?
                        htmlToText(data?.data_trans?data?.data_trans[1]?.description_en: null)
                        :
                        htmlToText(data?.data_trans?data?.data_trans[0]?.description_ar: null)
                        }
                      </p>
                    </>
                  ) : null
                ) : (
                  <>
                    <Skeleton height={40} />
                    <Skeleton height={40} sx={{ margin: "5px 0" }} />
                    <Skeleton height={200} />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about__image">
                <div className="about__image-bottom">
                  <div className="about__image-bottom-hover">
                    {loading === false ? (
                      data ? (
                        <img
                          src={data?.image}
                          alt="the company img"
                          className="img-fluid"
                          loading="lazy"
                        />
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
