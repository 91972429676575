import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getOurInfo } from "../../../Redux/Redux-Toolkit/Slices/OurSettings/OurInfoSlice";

const OurInfoHook = () => {
  const dispatch = useDispatch();

  const response = useSelector((state) => state.rootReducer.OurInfoReducer);

  const [data, set_data] = useState([]);
  const [loading, set_loading] = useState(true);
  // function for states
  const get_data = async()=>{
        set_loading(true)
        await dispatch(getOurInfo())
        set_loading(false)
  }
  useEffect(()=>{
    if(response?.ourInfo.length < 1){
        get_data()
    }else{return}
  },[])
  useEffect(()=>{
    if(loading===false){
        if(response){
            set_data(response?.data)
        }
    }
  },[response,loading])
  // on submit fn
  return [data,loading]
};

export default OurInfoHook;
