import React from "react";
import "./ListingPage.css";
import ListingSidebar from "../../components/Listing/ListingSidebar/ListingSidebar";
import Footer from "../../components/GeneralSections/Footer/Footer";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import BreadcrumbSection from "../../components/GeneralSections/BreadcrumbSection/BreadcrumbSection";
import ListContent from "../../components/Listing/ListContent/ListContent";
import { useTranslation } from "react-i18next";

const ListingPage = () => {
  const {t} = useTranslation()
  return (
    <div className="listing-page page">
      <InternalHeader listsActive={"listsActive"}/>
      <BreadcrumbSection title={t("listingPage.breadcrumbTitle")} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <ListingSidebar />
            </div>
            <div className="col-lg-9">
             <ListContent />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default ListingPage;
