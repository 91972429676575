import React, { useEffect, useState } from 'react'
import Card from "../../../components/Utility/Cards/Card/Card";
import HorizontalCard from "../../../components/Utility/Cards/HorizontalCard/HorizontalCard";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent"
import FilterHook from '../../../CustomHooks/FiltrationHooks/FilterHook';
import CardSkeleton from '../../Utility/Cards/CardSkeleton/CardSkeleton';
import HorizontalCardSkeleton from '../../Utility/Cards/HorizontalCardSkeleton/HorizontalCardSkeleton';
import NotFound from '../../Utility/NotFound/NotFound';
import CustomPagination from '../../Utility/CustomPagination/CustomPagination';
import GetAllWishlistHook from '../../../CustomHooks/Wishlist/GetAllWishlistHook'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faList } from '@fortawesome/free-solid-svg-icons';
const ListContent = () => {
  const [t] = useTranslation()
    const [active, setActive] = useState("vertical-cards");
    const  [units,loading,,,,,,,,,,,,,,,,,,,,,,,,,onPressPaginate,,onChangeSort,,,,,unitsCount,RefreshData]= FilterHook()
    const [,,wishlistIds] = GetAllWishlistHook()
    const sort_options = [
      { value: "price_asc", label:t("listingPage.listContent.lowTo")},
      { value: "price_desc", label:t("listingPage.listContent.highTo") },
    ];
    useEffect(()=>{
      RefreshData()
    },[])
  return (
    <div className="list-content">
      <div className="tabs__custom-v2">
        <nav className="d-flex align-items-center justify-content-between flex-nowrap">
        <ul className="nav nav-pills nav-pills-1 myTab" role="tablist">
          <li className="list-inline-item mr-auto d-flex flex-nowrap align-items-center w-100">
            <span className="title-text">{t("listingPage.listContent.sortBy")}</span>
            <div className="select-wrapper">
                <SelectComponent options={sort_options} placeholder={t("listingPage.listContent.basedProperties")} onChange={onChangeSort}/>
            </div>
          </li>
        </ul>
        <div className='d-none d-sm-block'>
        <ul className="nav nav-pills myTab" role="tablist">
          <li
            className={
              active === "vertical-cards"
                ? "nav-item transition active"
                : "nav-item transition"
            }
            onClick={() => setActive("vertical-cards")}
          >
           <FontAwesomeIcon icon={faGrip}/>
          </li>
          <li
            className={
              active === "horizontal-cards"
                ? "nav-item transition active"
                : "nav-item transition"
            }
            onClick={() => setActive("horizontal-cards")}
          >
            <FontAwesomeIcon icon={faList}/>
          </li>
        </ul>
        </div>
        </nav>
        <div className={
            active === "vertical-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false? 
              units?.data?.length>0?
              units?.data?.map((item,i) => (
                <div className="col-sm-6 col-xl-4" key={item?.id}>
                <Card item={item} key={item?.id} favItem={wishlistIds}/>
              </div>
              ))
              :(units?.data?.length<1 && <NotFound/>)
              :
              <>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              <div className="col-sm-6 col-lg-4">
              <CardSkeleton />
              </div>
              </>
              }
            </div>
          </div>
        </div>
        <div className={
            active === "horizontal-cards"
              ? "tab-content active"
              : "tab-content"
          }
          id="myTabContent"
        >
          <div
            className="tab-pane fade show active my-4"
            id="pills-tab-two"
            role="tabpanel"
            aria-labelledby="pills-tab-two"
          >
            <div className="row units-wrapper">
              {
              loading===false?
              unitsCount>0?
              units?.data?.map((item, i) => (
                <div className="col-12" key={item?.id}>
                  <HorizontalCard item={item} key={item?.id} favItem={wishlistIds} />
                </div>
              ))
              :(unitsCount<1 && <NotFound/>)
              :
              <>
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              <HorizontalCardSkeleton />
              </>
              }
            </div>
          </div>
        </div>
        <div className='pagination-content d-flex align-items-center justify-content-center' >
              <CustomPagination
                totalPages={units?.meta?.last_page}
                currentPage={units?.meta?.current_page}
                onChange={onPressPaginate}
              />
        </div>
      </div>
  </div>
  )
}

export default ListContent
