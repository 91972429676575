import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCities } from "../../Redux/Redux-Toolkit/Slices/City/CitySlice";
const GetAllCitiesHook = () => {
  const [loading,setLoading] = useState(false);
  const [cities,set_cities] = useState([]);
  const res = useSelector((state) => state.rootReducer.cityReducer);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllCities());
    setLoading(false);
  }
  useEffect(() => {
    if(!res?.allCities?.data){
      getData();
    }
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res?.allCities?.data) {
          set_cities(res?.allCities?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,cities];
};

export default GetAllCitiesHook;
