import React, { useState } from "react";
import "./ListingSidebar.css";
import BtnComponent from "../../../components/Utility/BtnComponent/BtnComponent";
import { Collapse } from "react-bootstrap";
// import element of material ui {range slider}
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Skeleton } from "@mui/material";
import SelectComponent from "../../Utility/SelectComponent/SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import GetAllPropertiesHook from "../../../CustomHooks/PropertiesHook/GetAllPropertiesHook";
import GetAllCategoriesHook from "../../../CustomHooks/Categories/GetAllCategoriesHook";
import GetAllCitiesHook from "../../../CustomHooks/CityHook/GetAllCitiesHook";
import FilterHook from "../../../CustomHooks/FiltrationHooks/FilterHook";
import FormatNumberHook from "../../../CustomHooks/FormatNumber/FormatNumberHook";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
const ListingSidebar = () => {
  // translation
  const lang= Cookies.get("i18next")
   const [t] = useTranslation();
  // start for range slider
  const [value, setValue] = useState([0, 1000]);
  // filter hook
  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    searchWord,
    ,
    ,
    minPrice,
    maxPrice,
    ,
    onChangeSpace,
    onChangeBathrooms,
    ,
    onChangeType,
    onChangeSearchWord,
    onChangeRooms,
    onChangePropertyId,
    onChangeCategoryId,
    onChangeMaxPrice,
    onChangeMinPrice,
    onSearch,
    Reset,
    ,
    ,
    ,
    ,
    onChangeCityId,
    onChangeCode,
    code,
  ] = FilterHook();
  // end filter hook
  // hook to get the various data
  const [properties_loading, properties] = GetAllPropertiesHook();
  const [categories_loading, categories] = GetAllCategoriesHook();
  const [cities_loading, cities] = GetAllCitiesHook();
  const [formatNumber] = FormatNumberHook();
  // End for range slider
  // advanced filter
  const [open, setOpen] = useState(false);
  const [showState, setShowState] = useState("+");
  // handle change price
  const handleChange = (event, newValue) => {
    onChangeMinPrice(newValue[0]);
    onChangeMaxPrice(newValue[1]);
    setValue(newValue);
  };
  // handle collapse for advanced filter
  const handleCollaps = () => {
    setOpen(!open);
    showState === "+" ? setShowState("-") : setShowState("+");
  };
  // End advanced filter
  const handleTypeLang =()=>{
    if(lang==="en"){
      return localStorage.getItem("type")==="sale"?"sale":"rent"
    }else{
        return localStorage.getItem("type")==="sale"?"بيع":"ايجار"
    }
   
  }
  const typeList = [
    { value: "sale", label: t("listingPage.listingSidebar.sale") },
    { value: "rent", label:t("listingPage.listingSidebar.rent")  },
  ];
  const categoryList = [
    categories_loading === false
      ? categories
        ? categories?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
              label: `${
                lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
            };
          })
        : null
      : null,
  ];
  const CitiesList = [
    cities_loading === false
      ? cities
        ? cities?.map((item, i) => {
            return {
              id: `${item?.id}`,
              value: `${
                lang==="en"?
                item?.data_trans?item?.data_trans[1]?.title_en:null
                :
                item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
              label: `${
                lang==="en"?
                item?.data_trans?item?.data_trans[1]?.title_en:null
                :
                item?.data_trans?item?.data_trans[0]?.title_ar:null
              }`,
            };
          })
        : null
      : null,
  ];
  const options_3 = [
    { value: "1500", label: "1500" },
    { value: "1200", label: "1200" },
    { value: "900", label: "900" },
    { value: "600", label: "600" },
  ];
  const options_5 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];
  const options_6 = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  // get all cities and all categories
  let categoryValueResult = "";
  let cityValueResult = "";
  const getCategoryValue = () => {
    let result1 = categoryList
      ? categoryList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("category_id");
        })
      : null;
    categoryValueResult = result1 ? result1[0]?.value : "";
  };
  getCategoryValue();
  const getCityValue = () => {
    let result2 = CitiesList
      ? CitiesList[0]?.filter((item) => {
          return item?.id === localStorage.getItem("cityId");
        })
      : null;
    cityValueResult = result2 ? result2[0]?.value : "";
  };
  getCityValue();
  // react share component show
  const [active, setActive] = useState(false);
  const handleChangeActive = () => {
    setActive(!active);
  };
  // End react share
  return (
    <div className="listing-sidebar">
      <div className="products__filter mb-30">
        <div className="products__filter__group">
          <div className="products__filter__header">
            <h5 className="text-capitalize fw-bold">
              {t("listingPage.listingSidebar.title")}
            </h5>
          </div>
          <div className="products__filter__body my-3">
            <div className="form-group">
              <form onSubmit={onSearch}>
                <div className="row no-gutters my-2">
                  <div className="col-8">
                    <input
                      className="custom-input"
                      value={searchWord}
                      onChange={onChangeSearchWord}
                      type="search"
                      placeholder={t("listingPage.listingSidebar.search")}
                    />
                  </div>
                  <div className="col-4">
                    <BtnComponent
                      icon={<i className="fa fa-search"></i>}
                      type={"submit"}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="form-group">
              <input
                className="custom-input"
                value={code}
                onChange={onChangeCode}
                type="search"
                placeholder={t("listingPage.listingSidebar.typeCode")}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={typeList}
                placeholder={t("listingPage.listingSidebar.type")}
                onChange={onChangeType}
                value={{
                  value: localStorage.getItem("type"),
                  label:handleTypeLang() ||
                    t("listingPage.listingSidebar.type"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={categoryList[0]}
                placeholder={t("listingPage.listingSidebar.category")}
                onChange={onChangeCategoryId}
                value={{
                  value: categoryValueResult,
                  label:
                    categoryValueResult ||
                    t("listingPage.listingSidebar.category"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_3}
                placeholder={t("listingPage.listingSidebar.area")}
                onChange={onChangeSpace}
                value={{
                  value: localStorage.getItem("space"),
                  label:
                    localStorage.getItem("space") ||
                    t("listingPage.listingSidebar.area"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={CitiesList[0]}
                placeholder={t("listingPage.listingSidebar.cityName")}
                onChange={onChangeCityId}
                value={{
                  value: cityValueResult,
                  label:
                    cityValueResult || t("listingPage.listingSidebar.cityName"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_5}
                placeholder={t("listingPage.listingSidebar.rooms")}
                onChange={onChangeRooms}
                value={{
                  value: localStorage.getItem("rooms"),
                  label:
                    localStorage.getItem("rooms") ||
                    t("listingPage.listingSidebar.rooms"),
                }}
              />
            </div>
            <div className="form-group">
              <SelectComponent
                options={options_6}
                placeholder={t("listingPage.listingSidebar.bathrooms")}
                onChange={onChangeBathrooms}
                value={{
                  value: localStorage.getItem("bathRooms"),
                  label:
                    localStorage.getItem("bathRooms") ||
                    t("listingPage.listingSidebar.bathrooms"),
                }}
              />
            </div>
            <div className="form-group price-range-slider">
              <Box sx={{ width: "100%" }}>
                <Typography id="track-inverted-slider" gutterBottom>
                  {t("listingPage.listingSidebar.priceRange")}
                  <span className="parent-span">
                    <span className="value-0 d-block">{`${t(
                      "listingPage.listingSidebar.EGP"
                    )} ${formatNumber(minPrice)}`}</span>
                    <span>--</span>
                    <span className="value-1">{`${t(
                      "listingPage.listingSidebar.EGP"
                    )} ${formatNumber(maxPrice)}`}</span>
                  </span>
                </Typography>
                <Slider
                  value={value}
                  onChange={handleChange}
                  min={0}
                  max={50000000}
                  step={100000}
                />
              </Box>
            </div>
            <div className="form-group mb-0 mt-2">
              <div
                className="btn-box fw-bold my-3 show-more"
                aria-controls="example-collapse-text"
                aria-expanded={open}
                onClick={handleCollaps}
              >
                <BtnComponent
                  value={`${t(
                    "listingPage.listingSidebar.advancedFilter"
                  )} ${showState}`}
                  st={"w-100"}
                />
              </div>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  {properties_loading === false ? (
                    properties?.map((item, i) => {
                      return (
                        <label
                          key={item?.id}
                          className="custom-checkbox text-capitalize"
                        >
                          {lang === "en"
                            ? item?.data_trans
                              ? item?.data_trans[0]?.title_en
                              : null
                            : item?.data_trans
                            ? item?.data_trans[1]?.title_ar
                            : null}
                          <input
                            type="checkbox"
                            value={
                              item?.data_trans
                                ? item?.data_trans[0]?.title_en
                                : null
                            }
                            id={item?.id}
                            onChange={onChangePropertyId}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })
                  ) : (
                    <>
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width={150}
                        height={30}
                        sx={{ margin: "5px 0" }}
                      />
                    </>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
          <div className="products__filter__footer">
            <div className="form-group mb-0" onClick={onSearch}>
              <BtnComponent
                value={t("listingPage.listingSidebar.search")}
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
            <div className="form-group mb-0" onClick={Reset}>
              <BtnComponent value={t("listingPage.listingSidebar.reset")} />
            </div>
          </div>
        </div>
      </div>
      <div className=" wrapper__list__category my-5">
        <div className="widget widget__archive">
          <div className="widget__title">
            <h5 className="text-dark mb-0">
              {t("listingPage.listingSidebar.categoriesProperty")}
            </h5>
          </div>
          <ul className="list-unstyled">
            {categories_loading === false ? (
              categories?.map((item, i) => {
                return (
                  <li
                    className="categories-list-item"
                    id={item?.id}
                    key={item?.id}
                    onClick={onChangeCategoryId}
                  >
                    {
                lang==="en"?item?.data_trans?item?.data_trans[1]?.title_en:null:item?.data_trans?item?.data_trans[0]?.title_ar:null
                  }
                    <span className="badge badge-primary mx-1">
                      {item?.count_unit}
                    </span>
                  </li>
                );
              })
            ) : (
              <>
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
                <Skeleton
                  variant="rounded"
                  width={150}
                  height={30}
                  sx={{ margin: "5px 0" }}
                />
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="download mb-0">
        <div
          className="download__item my-2 rounded"
          onClick={() => handleChangeActive()}
        >
          <span role="button">
            <i className="fa fa-share mr-3 mx-1" aria-hidden="true"></i>
            {t("listingPage.listingSidebar.share")}
          </span>
        </div>
      </div>
      {active && (
        <ShareComponent
          handleChange={handleChangeActive}
          shareUrl={window.location.href}
        />
      )}
    </div>
  );
};

export default ListingSidebar;
