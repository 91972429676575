import React from 'react'

const SingleDetailVideo = ({unitDetails}) => {
  return (
    <div className="single__detail-features single-detail-video">
    <h6 className="text-capitalize detail-heading">
      property video
    </h6>
    <div className="single__detail-features-video">
      <figure className=" mb-0">
        <div className="home__video-area text-center">
          <video  >
          <source src={unitDetails?.video} type="video/mp4" />
          </video>
        </div>
      </figure>
    </div>
  </div>
  )
}

export default SingleDetailVideo
