import React, { useState } from "react";
import './SingleDetailsSidebar.css'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faShare } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import ShareComponent from "../../Utility/Share/ShareComponent/ShareComponent";
import { useTranslation } from "react-i18next";

const SingleDetailsSidebar = ({item}) => {
  const [active,setActive] = useState(false)
  const handleChangeActive = ()=>{
    setActive(!active)
  }
  const [t] = useTranslation()
  return (
    <div className="single-details-sidebar my-3">
          <div className="profile__agent__body">
           <div className="contacts my-2">
            <div className="whatsapp-btn-wrapper">
              <a  href={`https://wa.me/+20${item?.user?.whatsapp}`} className="contact-btn whatsapp-btn">
              {t("singleDetailsPage.singleDetailsSidebar.whatsapp")} <FontAwesomeIcon icon={faWhatsapp}/>
                </a>
            </div>
            <div className="phone-btn-wrapper">
              <a href={`tel:+20${item?.phone}`} className="contact-btn phone-btn ">
              {t("singleDetailsPage.singleDetailsSidebar.phone")} <FontAwesomeIcon icon={faPhone}/>
                </a>
            </div>
            <div className="email-btn-wrapper">
              <a href={`mailto:${item?.email}`} className="contact-btn email-btn">
              {t("singleDetailsPage.singleDetailsSidebar.email")} <FontAwesomeIcon icon={faEnvelope}/>
                </a>
            </div>
            <div className="share-btn-wrapper">
              <button  className="contact-btn share-btn" onClick={()=>handleChangeActive()}>
              {t("singleDetailsPage.singleDetailsSidebar.share")} <FontAwesomeIcon icon={faShare}/>
                </button>
            </div>
           </div>
          </div>
          {active&&<ShareComponent handleChange={handleChangeActive} shareUrl={window.location.href}/>}
    </div>
  );
};

export default SingleDetailsSidebar;
