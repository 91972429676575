
const HtmlToTextHook = () => {
    function htmlToText(htmlContent) {
        // Remove HTML tags
        let text = htmlContent?.replace(/<[^>]+>/g, '');
        // Decode HTML entities
        text = text?.replace(/&nbsp;/g, ' ');
        text = text?.replace(/&amp;/g, '&');
        text = text?.replace(/&lt;/g, '<');
        text = text?.replace(/&gt;/g, '>');
        text = text?.replace(/&quot;/g, '"');
        text = text?.replace(/&apos;/g, "'");
      
        // Remove extra whitespace
        text = text?.trim()?.replace(/\s+/g, ' ');
      
        return text;
    }
    return[htmlToText]
}

export default HtmlToTextHook
