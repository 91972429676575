import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {GetUnitDetails } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetUnitDetailsHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [unit_details,set_unit_details] = useState([]);
  const res = useSelector((state) => state.rootReducer.unitsReducer.unitDetails);
  const dispatch = useDispatch();
  const getData =async () => {
    setLoading(true);
    await dispatch(GetUnitDetails(id));
    setLoading(false);
  }
  useEffect(() => {
    getData(); 
  },[]);

  useEffect(() => {
    getData(); 
  },[id]);

  useEffect(() => {
    try{
      if (loading === false) {
       if(res?.status === 200){
        set_unit_details(res?.data)
       }
      }
    }catch(e){}
  },[loading]);


  return [loading,unit_details];
};

export default GetUnitDetailsHook;
