import React from "react";

const SingleDetailAmenities = ({unitDetails}) => {
  return (
    <div className="single__detail-features single-detail-amenities">
      <h6 className="text-capitalize detail-heading">features</h6>
      <ul className="list-unstyled icon-checkbox">
        {
          unitDetails?.property?.map((item,i)=>{return(
            <li key={i}>{item?.data_trans?item?.data_trans[0]?.title_en:null}</li>
          )})
        }
      </ul>
    </div>
  );
};

export default SingleDetailAmenities;
