import React from "react";
import AuthTitle from '../../../components/Auth/AuthTitle/AuthTitle'
import { useTranslation } from "react-i18next";
const ForgetPassPage = () => {
  const [t] = useTranslation()
  return (
    <div className="forget-pass-page auth-page page">
      <section className="section">
        <div className="auth-content">
          <div className="auth-page-title">
          <AuthTitle value={"SIG"}/>
          </div>
          <div className="forget-pass-card auth-card ">
            <div className="card-body">
              <h4 className="card-title mb-4">{t("authPages.forgetPassPage.title")}</h4>
              <form action="#">
                <div className="form-group">
                  <input
                    className="custom-input"
                    placeholder={t("authPages.forgetPassPage.email")}
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn">
                  {t("authPages.forgetPassPage.submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgetPassPage;
