import React from 'react'
import ScrollToTopBtn from '../../components/Utility/ScrollToTopBtn/ScrollToTopBtn'
import HomeSectionSkeleton from '../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton';
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader"
import LandingSkeleton from '../../components/GeneralSections/Landing/LandingSkeleton/LandingSkeleton';
const PopularCity = React.lazy(()=>import("../../components/Home/PopularCity/PopularCity"))
const PopularProjects = React.lazy(()=> import("../../components/Home/PopularProjects/PopularProjects"))
const Footer = React.lazy(()=>import('../../components/GeneralSections/Footer/Footer'))
const Categories = React.lazy(()=>import('../../components/Home/FeaturedProperties/FeaturedProperties'))
// const OurTeam = React.lazy(()=>import('../../components/Home/OurTeam/OurTeam'))
const SearchBox = React.lazy(()=>import("../../components/GeneralSections/Landing/SearchBox/SearchBox"))
const HomeAbout = React.lazy(()=>import("../../components/Home/HomeAbout/HomeAbout"))
const Landing = React.lazy(()=>import("../../components/GeneralSections/Landing/Landing"))
const HomePage = () => {
  return (
    <div className='home-page'>
      <InternalHeader homeActive={"homeActive"}/>
       <React.Suspense fallback={<LandingSkeleton/>}>
       <Landing />
        </React.Suspense>
       <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <SearchBox/>
        </React.Suspense>
       <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <Categories/>
        </React.Suspense>
        <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <PopularCity />
        </React.Suspense>
        <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <HomeAbout />
        </React.Suspense>
        <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <PopularProjects />
        </React.Suspense>
        {/* <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <OurTeam />
        </React.Suspense> */}
        <React.Suspense fallback={<HomeSectionSkeleton/>}>
        <Footer/>
        </React.Suspense>
        <ScrollToTopBtn />
    </div>
  )
}

export default HomePage
