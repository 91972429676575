import React from "react";
import "./SingleDetailsPage.css";
import SlideShow from "../../components/SingleDetails/SlideShow/SlideShow";
import bigImg from "../../images/bg.jpg";
import ScrollToTopBtn from "../../components/Utility/ScrollToTopBtn/ScrollToTopBtn";
import Footer from "../../components/GeneralSections/Footer/Footer";
import InternalHeader from "../../components/GeneralSections/InternalHeader/InternalHeader";
import SingleDetailsSidebar from "../../components/SingleDetails/SingleDetailsSidebar/SingleDetailsSidebar";
import SingleDetailDesc from "../../components/SingleDetails/Features/SingleDetailDesc/SingleDetailDesc";
import SingleDetailInfo from "../../components/SingleDetails/Features/SingleDetailInfo/SingleDetailInfo";
import SingleDetailAmenities from "../../components/SingleDetails/Features/SingleDetailAmenities/SingleDetailAmenities";
import SingleDetailVideo from "../../components/SingleDetails/Features/SingleDetailVideo/SingleDetailVideo";
import SimiliarProperties from '../../components/SingleDetails/SimiliarProperties/SimiliarProperties'
import SingleDetailHead from "../../components/SingleDetails/SingleDetailHead/SingleDetailHead";
import { useParams } from "react-router-dom";
import GetUnitDetailsHook from "../../CustomHooks/UnitsHook/GetUnitDetailsHook";
import HomeSectionSkeleton from "../../components/Home/HomeSectionSkeleton/HomeSectionSkeleton";
import DetailSkeleton from "../../components/SingleDetails/DetailSkeleton/DetailSkeleton";
const SingleDetailsPage = () => {
  const {id} = useParams();
  const [loading,unit_details] = GetUnitDetailsHook(id?.slice(1,id.length))
  return (
    <div className="single-details-page page">
      <InternalHeader breadcrumbTitle={"Single Property"} listsActive={"listsActive"}/>
      <section className="single__Detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {
                loading===false?
                <>
                <SingleDetailHead unitDetails={unit_details}/>
                <SlideShow unitDetails={unit_details}/>
                </>
                :
                <>
                <HomeSectionSkeleton/>
                </>
              }
            </div>
            <div className="col-lg-4 mt-2 mb-5 pt-3">
              <SingleDetailsSidebar />
            </div>
            <div className="col-lg-8">
              {
                loading===false?
                <>
              <SingleDetailDesc unitDetails={unit_details}/>
              <SingleDetailInfo unitDetails={unit_details}/>
              {unit_details?.property?.length>0&&<SingleDetailAmenities unitDetails={unit_details}/>}
              {unit_details?.video&&<SingleDetailVideo bigImg={bigImg} unitDetails={unit_details}/>}
                </>
                :
                <>
                <DetailSkeleton/>
                <DetailSkeleton/>
                <DetailSkeleton/>
                </>
              }
             
            </div>
          </div>
          <div className="row my-5">
            <div className="col-lg-12">
              <SimiliarProperties id={unit_details?.category?.id}/>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopBtn />
      <Footer />
    </div>
  );
};

export default SingleDetailsPage;
