import React, { useState } from 'react'
import FormatNumberHook from '../../../CustomHooks/FormatNumber/FormatNumberHook'
import ShareComponent from '../../Utility/Share/ShareComponent/ShareComponent'
import Cookies from "js-cookie"
const SingleDetailHead = ({unitDetails}) => {
  const [formatNumber] = FormatNumberHook()
  const [active,setActive] = useState(false)
  const handleChangeActive = ()=>{
    setActive(!active)
  }
  const lang= Cookies.get("i18next")
  return (
    <div className="single-detail-head single__detail-area pt-0 pb-4">
    <div className="row">
      <div className="col-md-8 text-start">
        <div className="single__detail-area-title">
          <h3 className="text-capitalize">
            {
              lang==="en"?
              unitDetails?.data_trans?
              unitDetails?.data_trans[1]?.title_en
              :
              null
              :
              unitDetails?.data_trans?
              unitDetails?.data_trans[0]?.title_ar
              :
              null
              
            }
          </h3>
          <p>{unitDetails?.location}</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="single__detail-area-price">
          <h3 className="text-capitalize text-gray price">{`EGP ${formatNumber(unitDetails?.price)}`}</h3>
          <ul className="list-row contacts justify-content-end">
              <li className="list-row-item">
                <a href={`https://wa.me/${unitDetails?.user?.whatsapp}`} target="_blank" rel="noreferrer" className="btn-social whatsapp">
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
              <li className="list-row-item">
                <a href={`tel:+${unitDetails?.user?.phone}`} target="_blank" rel="noreferrer" className="btn-social twitter">
                  <i className="fa fa-phone"></i>
                </a>
              </li>
              <li className="list-row-item">
                <a href={`mailto:${unitDetails?.user?.email}`} className="btn-social share">
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
              <li className="list-row-item">
                <button className="btn-social share" onClick={()=>handleChangeActive()}>
                  <i className="fa fa-share"></i>
                </button>
              </li>
          </ul>
        </div>
      </div>
    </div>
    {active&&<ShareComponent handleChange={handleChangeActive} shareUrl={window.location.href}/>}
  </div>
  )
}

export default SingleDetailHead
