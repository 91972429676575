import React from "react";
import FormatNumberHook from "../../../../CustomHooks/FormatNumber/FormatNumberHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
const SingleDetailInfo = ({unitDetails}) => {
  const [formatNumber] = FormatNumberHook()
  const lang= Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className="single__detail-features single-detail-info ">
      <h5 className="text-capitalize detail-heading fw-bold">{t("singleDetailsPage.singleDetailsInfo.title")}</h5>
      <div className="property__detail-info">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <ul className="property__detail-info-list list-unstyled">
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.propertyCode")}:</b> {unitDetails?.code}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.paying")} :</b> {unitDetails?.paying}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.type")}:</b> {unitDetails?.type}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.location")}:</b> {unitDetails?.location}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.price")}:</b> {`${t("singleDetailsPage.singleDetailsInfo.EGP")} ${formatNumber(unitDetails?.price)}`}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.propertySize")}:</b> {unitDetails?.size}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.rooms")}:</b> {unitDetails?.rooms}
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6">
            <ul className="property__detail-info-list list-unstyled">
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.serviceCharges")}:</b> {unitDetails?.service_charges}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.floor")}:</b> {unitDetails?.floor}
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.category")}:</b> {
                lang==="en"?unitDetails?.category?.data_trans?unitDetails?.category?.data_trans[1]?.title_en:"...."
                :
                unitDetails?.category?.data_trans?unitDetails?.category?.data_trans[0]?.title_ar:"...."
              }
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.country")}: </b> {
                  lang==="en"?
                  unitDetails?.country?.data_trans?unitDetails?.country?.data_trans[1]?.title_en:"...."
                  :
                  unitDetails?.country?.data_trans?unitDetails?.country?.data_trans[0]?.title_ar:"...."
                }
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.state")}:</b>  {
                  lang==="en"?
                  unitDetails?.state?.data_trans?unitDetails?.state?.data_trans[1]?.title_en:"...."
                  :
                  unitDetails?.state?.data_trans?unitDetails?.state?.data_trans[0]?.title_ar:"...."
                }
              </li>
              <li>
                <b>{t("singleDetailsPage.singleDetailsInfo.bathrooms")}:</b> {unitDetails?.bathroom}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDetailInfo;
