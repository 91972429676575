import React from 'react'
import Carousel from '../../Utility/Carousel/Carousel'
import SectionTitle from '../../Utility/SectionTitle/SectionTitle'
import GetSimilarUnitsHook from '../../../CustomHooks/UnitsHook/GetSimilarUnitsHook'
import { useTranslation } from 'react-i18next'

const SimiliarProperties = ({id}) => {
  const [loading,units] = GetSimilarUnitsHook(id)
  const [t] = useTranslation()
  return (
    <div className="similiar__item similiar-properties">
   <SectionTitle title={t("singleDetailsPage.SimiliarProperties.title")}/>
    <div className="similiar__property-carousel">
      {
        loading===false?
        units?
        <Carousel units={units}/>
        :
        null
        :
        null
      }
    </div>
  </div>
  )
}

export default SimiliarProperties
