import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import anonymous from "../../../images/anonymous.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeActive } from "../../../Redux/Redux-Toolkit/Slices/SidebarSlice/sidebarSlice";
import Cookies from 'js-cookie'
import UserProfileHook from "../../../CustomHooks/User/UserProfileHook";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
const Sidebar = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeActive());
  };
  const active = useSelector((state) => state.rootReducer.sidebarReducer.active);
  const [user,,] = UserProfileHook();
  const {t,i18n} = useTranslation()
  const lang = Cookies.get("i18next")
  const handleChangeLang = ()=>{
    window.location.reload()
    i18n.changeLanguage(lang==="ar"?"en":"ar")
  }
  return (
    <aside
      className={`home-sidebar d-block d-lg-none ${lang==="ar"?"home-sidebar-ar":"home-sidebar-en"} ${
        active === true ? "active" : ""
      }`}
      onClick={() => handleClick()}
    >
      <div className="sidebar-content">
       {typeof(Cookies.get("user")) !== "undefined" && (
          <div className="author-content position-relative">
             <div className="author-img avatar-sm"
                onClick={() => Navigate("/user-profile-page")}
              >
               {
                  user !==" " ?
                  <img src={user?.image || anonymous} alt={"profile_img"} loading="lazy" />
                  :
                  <Skeleton variant="circular" width={50} height={50} sx={{ margin:'0px 5px'}}/>
                }
              </div>
              <div className="author-bio">
                <div>
                {
                 user !==" " ?
                 <h4 className="author__title text-capitalize my-1">
                 <Link to={"/user-profile-page"}>{user?.name}</Link>
                  </h4>
                   :
                   <Skeleton variant="rounded" width={120} height={20} sx={{ margin:'5px 0'}}/>
                    }
                </div>
              </div>
          </div>
        )}
        <div className="sidebar-menu-wrap">
          <ul className="sidebar-menu">
            {typeof(Cookies.get("user")) !== "undefined" && (
              <Link
                to="/user-notifications-page"
                className={`sidebar-menu-item-1 sidebar-menu-item`}
              >
                <span>
                  <i className="fa fa-bell"></i>
                </span>
                <span> {t("generalSections.sidebar.notifications")}</span>
              </Link>
            )}
            {typeof(Cookies.get("user")) !== "undefined" && (
              <Link
                to="/user-wishlist-page"
                className={`sidebar-menu-item-4 sidebar-menu-item`}
              >
                <span>
                  <i className="fa fa-heart"></i>
                </span>
                <span>{t("generalSections.sidebar.wishlist")}</span>
              </Link>
            )}
            <Link
              to="/listing-page"
              className={`sidebar-menu-item-2 sidebar-menu-item`}
            >
              <span>
                <i className="fa fa-list"></i>
              </span>
              <span>{t("generalSections.sidebar.units")}</span>
            </Link>
            <Link
              to="/projects-page"
              className={`sidebar-menu-item-2 sidebar-menu-item`}
            >
              <span>
                <i className="fa fa-list"></i>
              </span>
              <span>{t("generalSections.sidebar.projects")}</span>
            </Link>
            <Link
              to="/contact-us-page"
              className={`sidebar-menu-item-5 sidebar-menu-item`}
            >
              <span>
                <i className="fa fa-envelope"></i>
              </span>
              <span>{t("generalSections.sidebar.contactUs")}</span>
            </Link>
            <Link
              to="/about-us-page"
              className={`sidebar-menu-item-5 sidebar-menu-item`}
            >
              <span>
                <i className="fa fa-users"></i>
              </span>
              <span>{t("generalSections.sidebar.aboutUs")}</span>
            </Link>
            {typeof(Cookies.get("user")) !== "undefined" && (
              <Link
                to="/user-setting-page"
                className={`sidebar-menu-item-5 sidebar-menu-item`}
              >
                <span className="mx-2">
                  <i className="fa fa-gear"></i>
                </span>
                <span>{t("generalSections.sidebar.setting")}</span>
              </Link>
            )}
            <li className="sidebar-menu-item">
              <span className="lang-span" role="button" onClick={()=>handleChangeLang()}>
              {lang==="ar"?"en":"ar"}
              </span>
              </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
