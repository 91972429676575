import React from "react";
import "./UserSettingPage.css";
import UserProfileSidebar from "../../../components/User/UserProfileSidebar/UserProfileSidebar";
import UserDashboardNav from "../../../components/User/UserDashboardNav/UserDashboardNav";
import UserDashboardBread from "../../../components/User/UserDashboardBread/UserDashboardBread";
import UserSettingInfoSection from "../../../components/User/UserSetting/UserSettingInfoSection";
import UserSettingPassSection from "../../../components/User/UserSetting/UserSettingPassSection";
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
const UserSettingPage = () => {
  const lang = Cookies.get("i18next")
  const [t] = useTranslation()
  return (
    <div className={`user-setting-page page ${lang==="en"?"":"user-setting-page-ar"}`}>
      <div className={`d-none d-lg-block ${lang==="en"?"user-sidebar-wrap":"user-sidebar-wrap-ar"}`}>
        <UserProfileSidebar settingPage={"settingPage"} />
      </div>
      <div className="user-dashboard">
        <UserDashboardNav settingsPageActive={"page-active"} />
        <UserDashboardBread section_title={t("user.userSettingPage.title")} />
        <div className="dashboard-main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
               <UserSettingInfoSection />
              </div>
              <div className="col-12">
               <UserSettingPassSection/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettingPage;
