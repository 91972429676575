import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProjects } from "../../Redux/Redux-Toolkit/Slices/Projects/ProjectsSlice";
const GetAllProjectsHook = () => {
  const [loading,setLoading] = useState(true);
  const [projects,setProjects] = useState([]);
  const res = useSelector((state) => state.rootReducer.projectsReducer.allProjects);
  const dispatch = useDispatch();

  const getData =async (page) => {
    setLoading(true);
    await dispatch(GetAllProjects(page));
    setLoading(false);
  }
  useEffect(() => {
    if(res?.data){
      setLoading(false)
      return
    }else{
      getData();
    }
  },[]);

  const onPressPaginate = (page)=>{
    getData(page)
  }
  useEffect(() => {
    try{
      if (loading === false) {
        if (res) {
          setProjects(res)
        }
      }
    }catch(e){}
  },[loading]);


  return [loading,projects,onPressPaginate];
};

export default GetAllProjectsHook;
