import { useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllSimilarUnits } from "../../Redux/Redux-Toolkit/Slices/Units/UnitsSlice";

const GetSimilarUnitsHook = (id) => {
  const [loading,setLoading] = useState(false);
  const [units,setUnits] = useState([]);
  const res = useSelector((state) => state.rootReducer.unitsReducer.similarUnits);
  const dispatch = useDispatch();

  const getData =async () => {
    setLoading(true);
    await dispatch(GetAllSimilarUnits(id));
    setLoading(false);
  }
  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    try{
      if (loading === false) {
        if (res) {
          setUnits(res?.data);
        }
      }
    }catch(e){}
  },[loading,res]);


  return [loading,units];
};

export default GetSimilarUnitsHook;
