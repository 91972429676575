import React from "react";
import "./ProjectCard.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie"
const ProjectCard = ({ item }) => {
  const Navigate = useNavigate();
  const lang= Cookies.get("i18next")
  const [t] =useTranslation()
  return (
    <div
      className="project-card row text-start"
      onClick={() => Navigate(`/project-details-page/:${item?.id}`)}
    >
      <div className="col-12 col-sm-6">
        <Link
          to={`/project-details-page/:${item?.id}`}
          className="profile__agency-logo"
        >
          <img
            src={item?.image}
            alt="project-img"
            className="img-fluid"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="col-12 col-sm-6">
        <div className="profile__agency-info">
          <h5 className="project-title text-capitalize">
            {lang === "en"
              ? item?.data_trans
                ? item?.data_trans[1]?.title_en
                : null
              : item?.data_trans
              ? item?.data_trans[0]?.title_ar
              : null}
          </h5>
          <p className="text-capitalize mb-1">
            {lang === "en"
              ? item?.data_trans
                ? item?.data_trans[1]?.address_en
                : null
              : item?.data_trans
              ? item?.data_trans[0]?.address_ar
              : null}
          </p>
          <ul className="list-unstyled mt-2">
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-building"></i>
                </span>
                <span> {t("projectsPage.projectCard.whatsapp")} : {item?.user?.whatsapp}</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-phone"></i>
                </span>
                <span> {t("projectsPage.projectCard.mobile")} : {item?.user?.phone}</span>
              </a>
            </li>
            <li>
              <a href="/" className="text-capitalize">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <span>{t("projectsPage.projectCard.email")} : {item?.user?.email}</span>
              </a>
            </li>
          </ul>
          <ul className="list-row">
            <li className="list-row-item">
              <a href={item?.user?.facebook} className="btn-social facebook">
                <FontAwesomeIcon icon={faFacebookF}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.user?.twitter} className="btn-social twitter">
              <FontAwesomeIcon icon={faTwitter}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.user?.linkedin} className="btn-social linkedin">
              <FontAwesomeIcon icon={faLinkedin}/>
              </a>
            </li>
            <li className="list-row-item">
              <a href={item?.user?.instagram} className="btn-social instagram ">
              <FontAwesomeIcon icon={faInstagram}/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
