import React,{useState} from 'react'
import './ReadMore.css'
const ReadMore = ({des}) => {
    const [show,setShow] = useState(false)
    return (
    <div className='read-more-less'>
        <p className={show===false?"ph":"show ph"}>
            {des}
        <span className='shadow-span'></span>
        </p>
        {
          des?.length>500?
          <button className='show_btn' onClick={()=>setShow(!show)}>Read {show===false?"More":"Less"}</button>
          :null
        }
    </div>
  )
}

export default ReadMore
